declare global {
  interface Config extends EnvConfig, CommonConfig {}

  interface EnvConfig {
    SITE_URL: string;
    API_URL: string;
    API_VERSION: string;
    MATOMO_URL: string;
    MATOMO_SITE_ID: string;
    STRIPE_KEY: string;
    STRIPE_PORTAL_URL: string;
    STAGE: string;
  }

  interface CommonConfig {
    NODE_ENV: string;
    MaxAttachmentSize: number;
  }
}

const dev: EnvConfig = {
  SITE_URL: process.env.GATSBY_SITE_URL ?? 'http://localhost:8000',
  API_URL: process.env.GATSBY_API_URL ?? 'http://localhost:4000',
  API_VERSION: process.env.GATSBY_API_VERSION ?? '/v4',
  MATOMO_URL: process.env.GATSBY_MATOMO_URL ?? 'https://ajassa.matomo.cloud',
  MATOMO_SITE_ID: process.env.GATSBY_MATOMO_SITE_ID ?? '2',
  STRIPE_KEY: process.env.GATSBY_STRIPE_KEY ?? '',
  STRIPE_PORTAL_URL: process.env.GATSBY_STRIPE_PORTAL_URL ?? '',
  STAGE: process.env.REACT_APP_STAGE ?? 'test',
};

const prod: EnvConfig = {
  SITE_URL: process.env.GATSBY_SITE_URL ?? '',
  API_URL: process.env.GATSBY_API_URL ?? '',
  API_VERSION: process.env.GATSBY_API_VERSION ?? '/v4',
  MATOMO_URL: process.env.GATSBY_MATOMO_URL ?? '',
  MATOMO_SITE_ID: process.env.GATSBY_MATOMO_SITE_ID ?? '2',
  STRIPE_KEY: process.env.GATSBY_STRIPE_KEY ?? '',
  STRIPE_PORTAL_URL: process.env.GATSBY_STRIPE_PORTAL_URL ?? '',
  STAGE: process.env.REACT_APP_STAGE ?? 'production',
};

const common: CommonConfig = {
  NODE_ENV: process.env.NODE_ENV ?? 'development',
  MaxAttachmentSize: 10000000,
};

const envConfig = process.env.NODE_ENV === 'development' ? dev : prod;
const config: Config = {
  ...common,
  ...envConfig,
};

if (process.env.NODE_ENV === 'development') {
  console.log('config: ', config);
}

export default config;
